import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Grid, Box } from '@mui/material';
import {
  Button,
  InlineAlert,
  Preloader,
  TextField,
  Typography,
} from '@northwell-health/nw-component-lib';
import { verifyPatientDemographics } from '../../services/phq2/api';
import { formatPhoneNumber, formatDateOfBirth } from '../../utils/helpers';
import PHQ2 from './PHQ2';
import { useProgress } from '../context/ProgressContext';

const pageTitle = 'Patient health questionnaire form';

export default function VerificationForm() {
  //UseProgress Context Hook
  const { updateProgress } = useProgress();
  // Breakpoints
  const isMobile = useMediaQuery({ maxWidth: 767 });
  // Regex expressions for form validations.
  const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}$/;
  const phoneRegex = /^(\d{10})$/;
  const [searchParams] = useSearchParams();
  const sourceData = searchParams.get('sourceData') || undefined;
  // Set Patient Identifier
  const [patientIdentifier, setPatientIdentifier] = useState('');
  // Set loading state.
  const [loading, setLoading] = useState(false);
  // Form input elements for adding visitor.
  const [dateField, setDateField] = useState('');
  const [phoneField, setPhoneField] = useState('');
  const [validated, setValidated] = useState(false);
  // Set error alert state.
  const [errorAlert, setErrorAlert] = useState(false);
  // Set error alert message string.
  const [errorAlertMessage, setErrorAlertMessage] = useState('');
  // Input validation errors.
  const [inputErrors, setInputErrors] = useState({
    dob: undefined,
    phone: undefined,
  });

  useEffect(() => {
    document.querySelector('title').textContent = pageTitle;
    document.getElementById('pageTextHeader').innerHTML = pageTitle;
    // Set Value For Progress
    updateProgress(25);
  }, []);

  // Auto add slashes to user's input when adding date of birth field.
  const addSlashesToDOB = (event) => {
    event.target.value = event.target.value
      .replace(/^(\d\d)(\d)$/g, '$1/$2')
      .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
      .replace(/[^\d/]/g, '');
  };

  // Handle capturing the data from fields.
  const handleFieldChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case 'dob':
        addSlashesToDOB(event);
        setDateField(value);
        break;
      case 'phone':
        setPhoneField(value);
        break;
      default:
        break;
    }
  };

  // Validate the form fields.
  const validate = () => {
    const tempErrors = {};
    if (!dateField || !dateRegex.test(dateField))
      tempErrors.dob = 'Invalid date of birth';
    if (!phoneField || !phoneRegex.test(phoneField))
      tempErrors.phone = 'Invalid phone number';
    setInputErrors(tempErrors);
    return Object.keys(tempErrors).every((key) => tempErrors[key] === '');
  };

  // Handle verifying the patient.
  const verifyPatient = async (event) => {
    try {
      event.preventDefault();
      // Check form validation.
      if (validate()) {
        // Show error if required parameter are missing.
        if (!sourceData) {
          throw 'Missing required parameters - sourceData';
        }
        setLoading(true);
        // Format the inputted data.
        const formattedDateOfBirth = formatDateOfBirth(dateField);
        const formattedMobileNumber = formatPhoneNumber(phoneField);
        // Verify the patient
        const verificationResults = await verifyPatientDemographics(
          sourceData,
          formattedDateOfBirth,
          formattedMobileNumber,
        );
        // Show error if api error.
        if (!verificationResults || verificationResults?.errors) {
          throw 'Something went wrong';
        }
        // Check if patient verified.
        if (verificationResults?.isVerified) {
          setLoading(false);
          setPatientIdentifier(verificationResults?.identifier);
          setValidated(true);
        } else {
          throw 'Patient not verified';
        }
      }
    } catch (e) {
      setLoading(false);
      setValidated(false);
      // Set Error message
      setErrorAlertMessage('We ran into an issue. Please try again');
      //Keep error alert true for 5 seconds.
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
      }, 5000);
    }
  };

  // Show loading screen.
  if (loading) {
    return <Preloader fullScreen text="Loading ..." />;
  }

  // Show the user PHQ2 component upon successful verification.
  if (validated) {
    return <PHQ2 patientIdentifier={patientIdentifier} />;
  }

  return (
    <Box className="pageContainer">
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="left"
        style={{ width: `${isMobile ? '100%' : 'fit-content'}` }}
      >
        {/** Subtext and other form information. */}
        <Grid item xs={12} style={{ marginTop: 48, marginBottom: 24 }}>
          <Typography type="h1" gutterBottom={true}>
            Your information
          </Typography>
          <Typography
            type="body"
            size={isMobile ? 'small' : 'large'}
            weight="light"
            gutterBottom={true}
          >
            Enter your date of birth and phone number.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginBottom: 24,
            display: 'block',
          }}
        >
          <Typography
            type="body"
            size="small"
            weight="light"
            style={{ color: 'red' }}
          >
            {'* '}
          </Typography>
          <Typography type="body" size="small" weight="light">
            Required
          </Typography>
        </Grid>
        {/** In line error alert */}
        {(inputErrors.dob || inputErrors.phone || errorAlert) && (
          <Grid item xs={12} style={{ marginBottom: 24 }}>
            <InlineAlert
              severity="error"
              fullWidth
              text={
                errorAlert
                  ? errorAlertMessage
                  : `${
                      inputErrors.dob && inputErrors.phone
                        ? 'There are 2 errors below'
                        : 'There is 1 error below'
                    }`
              }
            />
          </Grid>
        )}
        {/** Verification form */}
        <Grid container>
          <form noValidate style={{ width: 'inherit' }}>
            <Grid
              item
              xs={12}
              style={{
                marginBottom: 24,
                maxWidth: '100%',
              }}
            >
              <TextField
                id="outlined-dob"
                label="Date of birth (MM/DD/YYYY)"
                variant="outlined"
                required
                name="dob"
                onChange={(event) => handleFieldChange(event)}
                maxLength={10}
                type="text"
                error={!!inputErrors?.dob}
                helperText={inputErrors?.dob}
                fullWidth
                hideCharCount
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                marginBottom: 24,
                maxWidth: '100%',
              }}
            >
              <TextField
                id="outlined-number"
                label="Phone number"
                variant="outlined"
                required
                name="phone"
                onChange={(event) => handleFieldChange(event)}
                maxLength={10}
                type="tel"
                error={!!inputErrors?.phone}
                helperText={inputErrors?.phone}
                fullWidth
                hideCharCount
              />
            </Grid>
          </form>
        </Grid>
        {/** Submit button to validate form */}
        <Grid item xs={12}>
          <Button
            type="submit"
            text="Continue"
            size="large"
            color="primary"
            onClick={verifyPatient}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
