import { useParams } from 'react-router-dom';
import React, { useEffect } from "react";
import { Grid, Box } from '@mui/material'
import { Typography } from '@northwell-health/nw-component-lib'
const pageTitle = 'Error Page';

export default function ErrorPage() {
  let { error } = useParams();

  const errorMessage = decodeURI(error);

  useEffect(() => {
    document.querySelector('title').textContent = pageTitle;    
    document.getElementById("pageTextHeader").innerHTML = pageTitle;
  }, []);

  return (
    <Box className="pageContainer">
      <Grid container
      direction="column"
      justifyContent="space-between"
      alignItems="left">
        <Grid item xs={12}>
          <Typography variant="h1" className="imgAlign noPad"
          sx={{
            marginTop: { xs: '1.25rem', sm: '1.25rem', md: '1.25rem', lg: '5rem', xl: '5rem' },
            marginBottom: { xs: '2rem', sm: '2rem', md: '2rem', lg: '2rem', xl: '2rem' },
            }}>
          Need Help?
          </Typography>
        </Grid>
        <Grid item xs={12} s={12} md={12}>
          <Typography variant="body" size="large" weight="plain" style={{ fontSize: 18 }}> 
            Give us a call weekdays from 8am to 8pm and weekends from 9am-5pm at <bold>888-321-DOCS</bold>, and we'll be happy to assist you.
          <br/><br/>
          Check out our answers to <a href="https://www.northwell.edu/help">frequently asked questions</a>
          <br/><br/>
          </Typography>
          <Typography variant="h4" style={{ marginBottom: 10  }} className="imgAlign noPad">
          Error Code / Description:
          </Typography>
          <Typography variant="body" size="large" weight="plain" style={{ fontSize: 18 }}>
              <br/>{errorMessage}
          </Typography>
        </Grid>
      </Grid>
    </Box>
    );
  };
  