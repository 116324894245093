import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Grid } from '@mui/material';

export default function NorthwellLog() {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Grid
      container
      justifyContent={isMobile ? 'center' : 'flex-start'}
      alignItems="center"
      style={{
        height: '70px',
        marginLeft: isMobile ? 'auto' : '150px',        
        marginRight: isMobile ? 'auto' : '0',
      }}
    >
      <Grid item>
        <img src="/images/NorthwellHealthLogo.svg" alt="Northwell Health Logo" />
      </Grid>
    </Grid>
  );
}
