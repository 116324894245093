import React, { useState, useEffect } from "react";
import "./uploadcontrol.css";
import envConfig from "../../utils/configEnv/config";
import { useNavigate } from "react-router-dom";
import { Button, Box } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { Preloader, InlineAlert } from '@northwell-health/nw-component-lib'

const styleObj = {
  backgroundColor: "transparent",
  minWidth: "340px",
  fontSize: "1rem",
  fontFamily: `TheSans, BlinkMacSystemFont, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  color: "#007BC2",
  boxShadow: "none",
  borderRadius: "5px", 
  borderColor: "#007BC2",
  borderWidth: "1px",
  borderStyle: "solid",
  textTransform: "none",
  padding: "0px 32px",
  lineHeight: "24px",
  height: "48px",
  "&:hover": {
    color: "#5C0B8A",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  "&:active": {
    backgroundColor: "transparent",
  },
};
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function UploadFileCtrl(props) {
  const navigate = useNavigate();
  const { uploadStateChange } = props;

  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(null);
  const [uploadComplete, setUploadComplete] = useState(null);
  const [fileFormatMsg, setFileFormatMsg] = useState(false);
  const [uploadState, setUploadState] = useState(false);
  const [isFileTypeAllowed, setFileTypeAllowed] = useState(true);
  let savedPosition = 0;
  const scrollToAbsoluteTop = () => {
    window.scrollTo({
      top: 0,
      left: 0
        });
  };

  const UPLOAD_ENDPOINT = envConfig?.REACT_APP_UPLOAD_ENDPOINT;

  const handleFileSubmit = async () => {
    savedPosition = window.scrollY;

    if (!file) return;
    for (let i = 0; i < file.length; i++) {
      scrollToAbsoluteTop();
      setUploading(true);

      let res = await uploadFile(file[i]);
      if (res.status === 200) {
        setUploadComplete(true);
        window.scrollTo({
          top: savedPosition,
        });
        setUploading(false);
        setUploadState(false);
        uploadStateChange(true);
      } else {
        navigate('/Error/' + 'upload failed');
      }
    }
  };

  const uploadFile = async (file) => {
    const imageURI = {
      owner: props.patientName,
      type: file.type,
      name: file.name,
    };
    try {
      return await fetch(UPLOAD_ENDPOINT, {
        method: 'post',
        body: file,
        headers: {
          'Content-Type': 'multipart/form-data;',
          'formid': props.formid,
          'Image-Name-Str': imageURI.name,
          'Image-Type-Str': imageURI.type,
          'Image-Owner-Str': imageURI.owner,
        },
      });
    } catch (e) {
      navigate('/Error/' + 'upload failed');
    }
  };

  const isCorrectType = (fileType) => {
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
    return allowedTypes.includes(fileType.toLowerCase());
  };

const handleOnFileChange = (e) => {
  setUploadState(false);
  setUploadComplete(false);
  uploadStateChange(false);

  let validFiles = true;
  for (let i = 0; i < e.target.files.length; i++) {
    if (!isCorrectType(e.target.files[i].type)) {
      validFiles = false;
      break;
    }
  }

  setFileTypeAllowed(validFiles);
  setFile(e.target.files);
  setFileFormatMsg(!validFiles); // Show message if files are invalid
  if (validFiles) {
    setUploadState(true);
  }
};


  useEffect(() => {
    if (uploadState) {
      handleFileSubmit();
    }
  }, [uploadState]); // Automatically trigger when uploadState changes to true

  return (
    <Box>
      <Button
        component="label"
        variant="contained"
        color="secondary"
        sx={{ ...styleObj, width: 400, maxWidth: 400 }}
        startIcon={<CloudUploadIcon />}
      >
        Upload
        <VisuallyHiddenInput
          type="file"
          id="files"
          onChange={handleOnFileChange}
          multiple
          accept="image/png, image/jpeg, application/pdf"
        />
      </Button>
      {uploading && <Preloader fullScreen text="Uploading Files..." />}
      {uploadComplete && <Box className="uploadedFiles">
      <InlineAlert severity="success" text="Your file(s) have been uploaded" 
      dismissable
      fullWidth /></Box>}
      {fileFormatMsg && <Box className="uploadedFiles">
      <InlineAlert severity="warning" text="Please select a PDF, JPG or PNG of documentation required. Multiple file uploads allowed." dismissable  
      fullWidth/></Box>}               
      {!isFileTypeAllowed && <Box className="uploadedFiles">
      <InlineAlert severity="warning" text="Only PDF, JPG, or PNG files are allowed." 
      dismissable 
      fullWidth/></Box>}
    </Box>
  );
}
