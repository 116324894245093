import React from 'react';
import { useMediaQuery } from '@mui/material';
import { Typography, Grid } from '@mui/material';

export default function NorthwellHeader(props) {
  const isMobile = useMediaQuery('(max-width:767px)');
  const { formHeader } = props;

  return (
    <Grid
      container
      sx={{
        height: isMobile ? '56px' : 'auto',
        backgroundColor: '#009adf',
        alignItems: 'center',
        display: 'flex', 
        justifyContent: 'center',
        height: '72px',
      }}
    >
    <Grid item xs={12}>
        <Typography id="pageTextHeader"
          variant="h1"
          sx={{
            fontSize: { xs: '1.25rem', sm: '1.5rem', md: '1.75rem' },
            color: 'white',
            fontWeight: 'normal',
            textAlign: isMobile ? 'center' : 'left', // Center on mobile, align left web browse
            marginLeft: isMobile ? '0' : '150px', // No margin on mobile, Margin 160 web browse
          }}>
          {formHeader}
        </Typography>
      </Grid>
    </Grid>
  );
}
