import React from "react";
import { createRoot } from "react-dom/client";
import AppRoutes from "./routes/reactdomroutes";
import { ThemeProvider, nwTheme } from "@northwell-health/nw-component-lib";
import { Grid, Box } from '@mui/material';
import { styled } from '@mui/system';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fal } from "@fortawesome/pro-light-svg-icons";
import "./iformStyles.css";
import NorthwellHeader from "./components/northwellheader/northwellheader";
import NorthwellLogo from "./components/northwellheader/northwelllogoBar";
import ProgressBar from "./components/northwellheader/progressindicator";
import { Footer } from "@northwell-health/nw-component-lib";
import { ProgressProvider, useProgress } from "./components/context/ProgressContext"; // Import ProgressContext
library.add(fal);

const defaultHeader = "FollowMyHealth minor consent form";

function App() {
  const { progressValue } = useProgress();
  return (
<ThemeProvider theme={nwTheme}>
  <Grid
    container
    direction="column"
    style={{ minHeight: "100vh" }} // Important - Ensures Footer is positioned on bottom of screen
  >
    <Grid item>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <NorthwellLogo />
          <ProgressBar value={progressValue} />
        </Grid>
        <Grid item xs={12}>                
          <NorthwellHeader formHeader={defaultHeader} />          
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} style={{ flexGrow: 1 }}> 
      <main>
        <AppRoutes />
      </main>
    </Grid>          
    <Grid item>
      <Footer compact />  
    </Grid>
  </Grid>
</ThemeProvider>
  );
}

export default App;

const root = createRoot(document.getElementById("root"));
root.render(
  <ProgressProvider>
    <App />
  </ProgressProvider>
);
