import React, { useState,useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import SignaturePad from 'react-signature-canvas';
import "../formcomponents/ElectronicSignature.css";
import { Typography, Button } from '@northwell-health/nw-component-lib'
import { Grid, Box, Paper } from '@mui/material';
import styled from '@emotion/styled';
import { useProgress } from "../context/ProgressContext";

const Item = styled(Paper)(() => ({
  textAlign: 'left',
  minHeight: '65px',
  lineHeight: '60px',
}));

const styles = {
  signatureSectionCont: {
    padding: '10px'
  },
  errorMsgCont:{
    display: 'block',
    clear: 'both',
    width: '100%',
    paddingTop: '40px'
  }
};

const adultPageFour= (props) => {
  //UseProgress Context Hook
  const { updateProgress } = useProgress();
  updateProgress(85); 

  const { SubmissionState, onFormStateChange } = props;
  const { register,  handleSubmit, setValue, formState: {}} = useForm();
  const [ isSigned, setIsSigned ] = useState(false);
  const [imageURL, setImageURL] = useState(null); 
  const signData = useRef({});
  
  const setCardColor =function(color){
    let SigCardSt = document.getElementsByClassName("Card");
    let SigPadBk = document.getElementsByClassName("SigPad");
    SigPadBk[0].style.backgroundColor = color;
    SigCardSt[0].style.backgroundColor = color;
  }
  
  const clear = () => {
    setImageURL("reset");
    setIsSigned(false),    
    signData.current.clear(),
    setCardColor("#FAFAFA")
    };

  const save = () => setImageURL(   
    signData.current.getTrimmedCanvas().toDataURL("image/png"),
    setValue("imageURLStr", "True"),
    setCardColor("#E8F0FE"),
    setIsSigned(true)
    );
    
  const saveifNotEmpty = () => {
    if(signData.current.isEmpty()){
      alert('You must sign document'); 
    }else{
      save();
    }
  };
  
  const handleRegistration = (data) => {
    data.imageUrlGuardian = imageURL;
    SubmissionState.secB = data;
    // Submit Form Data
    SubmissionState.sectionFourForm = data;
    SubmissionState.submitCompletedForm = true;
    onFormStateChange();
  };

  const previousPageClick = () => {
    SubmissionState.goBackPageThree = true;
    onFormStateChange();
  };


  const scrolltoTop = function() {
    window.scrollTo(0, 0);
  };

  scrolltoTop();
  
  return (  
    <Box className="pageContainer">
      <Grid container
        spacing={1}
        justifyContent="space-between"
        alignItems="left">
        <Grid item xs={12} sm={12} md={12} >
              <p className="breadcrumb_nav" onClick={previousPageClick}>&#60; Patient Signature</p>            
        </Grid>      
        <Grid item xs={12} sm={12} md={12}>
        <Item elevation={0}>
            <Typography variant="h1" style={{ marginBottom: 24 }}>
           By signing below, I acknowledge and agree that: 
            </Typography>
            </Item>
            <Item elevation={0}>
            <ul>
              <li>I must comply with the Terms and Conditions on the Northwell Website to access the patient’s MyNorthwell account.  </li>
              <li>Access to MyNorthwell is provided by Northwell as a convenience to its patients and families and Northwell may deactivate access to my MyNorthwell account at any time for any reason. A patient or their Legal Representative may also revoke Proxy access to his/her account at any time.  </li>
              <li>I understand that Proxy access will include select, limited medical information from a patient’s medical record.  I may request a copy of this patient’s full medical record through other means. </li>
              </ul>
            </Item>
          </Grid>
          <Grid item xs={12}><br/></Grid>
  <form onSubmit={handleSubmit(handleRegistration)}>
      <Grid item xs={12}>
      <Item elevation={0}>
          <div className="Card">
          <h2>Signature Required *</h2>
          <div className="SigContainer">
            <SignaturePad penColor="black" canvasProps={{ className: "SigPad" }}  ref={signData} />
            <div>
              {!isSigned? <p className="signatureError">A Signature Is Required. Please sign and click "submit" button.</p>: null}
            </div>
              <div>
                  <div className="blockDis">
                    <Button size="small" text="Reset Signature" color="secondary" onClick={clear}  type="button"/>
                  </div>
                  <div className="blockDis">
                    <Button size="small" text="Sumbit" color="primary" onClick={saveifNotEmpty}  type="submit" />
                  </div>
              </div>
            </div>
          </div>
          <input {...register("imageURLStr", { required: true })} type="hidden" />          
          <div style={styles.errorMsgCont}></div>
          </Item>
          </Grid>
          </form>
    </Grid>
  </Box>
  );
};
export default adultPageFour;
