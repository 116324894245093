import React from "react";
import { Grid, Box  } from '@mui/material'
import { Typography } from '@northwell-health/nw-component-lib'

const adultPageFourSuccess = (props) => {
    return (
    <Box className="pageContainer">
        <Grid container
        direction="column"
        justifyContent="space-between"
        alignItems="left">
    <Grid item xs={12}>
    <img src="/images/check.png" className="imgAlign"></img>
    <Typography variant="h1" style={{ marginTop: 80 , marginBottom: 16 }} className="imgAlign noPad">Success!</Typography>
    </Grid>
    <Grid item xs={12} s={12} md={12}>
        <Typography variant="body" size="large" weight="plain" style={{ fontSize: 18 }}>
            Please expect an invite with instructions on how to create your Authorized Individual account within the next 5 days.
        </Typography> 
        </Grid>
    </Grid>
    </Box>
    );
};
export default adultPageFourSuccess;

