import envConfig from '../../utils/configEnv/config';

const touchworksUserId = envConfig?.REACT_APP_TOUCHWORKS_USER_ID;
const touchworksUserPassword = envConfig?.REACT_APP_TOUCHWORKS_USER_PASSWORD;

// General method to generate headers.
const buildRequestHeaders = (token = undefined) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  return token ? { Authorization: `Bearer ${token}`, ...headers } : headers;
};

// Get Touchworks Token.
export const getTouchworksToken = async () => {
  const dpxURL = 'https://' + envConfig?.REACT_APP_DPXURL;
  const tokenEndpoint = '/iforms/phq/touchworks/token';
  // Build url.
  const fullURL = `${dpxURL}${tokenEndpoint}`;
  // Build request options.
  const requestOptions = {
    method: 'POST',
    headers: buildRequestHeaders(),
  };
  // Make request.
  const response = await fetch(fullURL, requestOptions);
  const responseData = await response.json();
  // Return token.
  return responseData?.response?.SecurityToken;
};

// Retire Touchworks Token.
export const retireTouchworksToken = async (token) => {
  const dpxURL = 'https://' + envConfig?.REACT_APP_DPXURL;
  const tokenEndpoint = '/iforms/phq/touchworks/retireToken';
  // Build url.
  const fullURL = `${dpxURL}${tokenEndpoint}`;
  // Build request data
  const requestData = {
    token,
  };
  // Build request options.
  const requestOptions = {
    method: 'POST',
    headers: buildRequestHeaders(),
    body: JSON.stringify(requestData),
  };
  // Make request.
  const response = await fetch(fullURL, requestOptions);
  const responseData = await response.json();
  // Return.
  return responseData;
};

// Get User Authentication Magic Call to get provider ID.
export const getProviderID = async (Token) => {
  const dpxURL = 'https://' + envConfig?.REACT_APP_DPXURL;
  const magicCallEndpoint = '/iforms/phq/touchworks/magicCall';
  // Build url.
  const fullURL = `${dpxURL}${magicCallEndpoint}`;
  // Build request data
  const requestData = {
    Action: 'GetUserAuthentication',
    AppUserID: touchworksUserId,
    AppName: '',
    PatientID: '',
    Token,
    Parameter1: touchworksUserPassword,
    Parameter2: '',
    Parameter3: '',
    Parameter4: '',
    Parameter5: '',
    Parameter6: '',
    Data: '',
  };
  // Build request options.
  const requestOptions = {
    method: 'POST',
    headers: buildRequestHeaders(),
    body: JSON.stringify(requestData),
  };
  const response = await fetch(fullURL, requestOptions);
  const responseData = await response.json();
  // Return data.
  return responseData?.response?.[0]?.getuserauthenticationinfo?.[0]
    ?.ProviderID;
};

// Get Internal Touchworks Patient ID
export const getTouchworksPatientID = async (Token, PatientID) => {
  const dpxURL = 'https://' + envConfig?.REACT_APP_DPXURL;
  const magicCallEndpoint = '/iforms/phq/touchworks/magicCall';
  // Build url.
  const fullURL = `${dpxURL}${magicCallEndpoint}`;
  // Build request data
  const requestData = {
    Action: 'GetPatientByMRN',
    AppUserID: touchworksUserId,
    AppName: '',
    PatientID: '',
    Token,
    Parameter1: PatientID,
    Parameter2: '',
    Parameter3: '',
    Parameter4: '',
    Parameter5: '',
    Parameter6: '',
    Data: '',
  };
  // Build request options.
  const requestOptions = {
    method: 'POST',
    headers: buildRequestHeaders(),
    body: JSON.stringify(requestData),
  };
  const response = await fetch(fullURL, requestOptions);
  const responseData = await response.json();
  // Return data.
  return responseData?.response?.[0]?.getpatientbymrninfo?.[0]?.ID;
};

// Create encounter for patient.
export const createEncounter = async (Token, PatientID) => {
  const dpxURL = 'https://' + envConfig?.REACT_APP_DPXURL;
  const magicCallEndpoint = '/iforms/phq/touchworks/magicCall';
  const date = new Date();
  // Build url.
  const fullURL = `${dpxURL}${magicCallEndpoint}`;
  // Build request data
  const requestData = {
    Action: 'GetEncounter',
    AppUserID: touchworksUserId,
    AppName: '',
    PatientID,
    Token,
    Parameter1: 'NonAppt',
    Parameter2: date,
    Parameter3: 'N',
    Parameter4: 'N',
    Parameter5: '',
    Parameter6: '',
    Data: '',
  };
  // Build request options.
  const requestOptions = {
    method: 'POST',
    headers: buildRequestHeaders(),
    body: JSON.stringify(requestData),
  };
  const response = await fetch(fullURL, requestOptions);
  const responseData = await response.json();
  // Return data.
  return responseData?.response?.[0]?.getencounterinfo?.[0]?.EncounterID;
};

// Save Order and Multiple Results Magic Call.
export const saveMultipleResults = async (PatientID, Token, Parameter1) => {
  const dpxURL = 'https://' + envConfig?.REACT_APP_DPXURL;
  const magicCallEndpoint = '/iforms/phq/touchworks/magicCall';
  // Build url.
  const fullURL = `${dpxURL}${magicCallEndpoint}`;
  // Build request data
  const requestData = {
    Action: 'SaveOrderAndMultipleResults',
    AppUserID: touchworksUserId,
    AppName: '',
    PatientID,
    Token,
    Parameter1,
    Parameter2: '',
    Parameter3: '',
    Parameter4: '',
    Parameter5: '',
    Parameter6: '',
    Data: '',
  };
  // Build request options.
  const requestOptions = {
    method: 'POST',
    headers: buildRequestHeaders(),
    body: JSON.stringify(requestData),
  };
  const response = await fetch(fullURL, requestOptions);
  const responseData = await response.json();
  // Return data.
  return responseData?.response?.[0]?.saveorderandmultipleresultsinfo?.[0]
    ?.status;
};
