import React, { useEffect } from "react";
import { Grid, Box, Paper } from '@mui/material'
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { Typography } from '@northwell-health/nw-component-lib'


const Item = styled(Paper)(({ theme }) => ({
  textAlign: 'left',
  height: '24px',
  paddingRight: '12px',
  lineHeight: '24px',
}));

const scrolltoTop = function() {
    window.scrollTo(0, 0);
};

export default function EmailNotification() {
  let { email } = useParams();

  useEffect(() => {
    scrolltoTop();
  }, []);
   
    return ( 
    <Box className="pageContainer">
      <Grid container
        direction="column"
        justifyContent="space-between"
        alignItems="left">
        <Grid item xs={12}>
          <Typography variant="h1" 
          sx={{
            marginTop: { xs: '1.25rem', sm: '1.25rem', md: '1.25rem', lg: '5rem', xl: '5rem' },
            marginBottom: { xs: '2rem', sm: '2rem', md: '2rem', lg: '2rem', xl: '2rem' },
            }}>
            Verify your email</Typography>
        </Grid>
        <Grid item xs={12}>
          <Item elevation={0}>
            <Typography variant="body" size="large" weight="plain" style={{ fontSize: 18 }}>
              An email has been sent to this <strong>{email}</strong>. Please verify your email address, by clicking the button "Verify email" in the email to continue.
            </Typography>
          </Item>
        </Grid>
      </Grid>
      </Box>
      );
    }
