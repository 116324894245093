import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Grid, Box } from '@mui/material';
import {
  Button,
  InlineAlert,
  Preloader,
  RadioButtons,
  Typography,
} from '@northwell-health/nw-component-lib';
import { PHQ2 } from '../../forms/phq2';
import { formatPHQ2Results } from '../../utils/helpers';
import * as touchworksService from '../../services/phq2/touchworks';
import InformationPage from './information';
import ResultsPage from './results';
import { useProgress } from '../context/ProgressContext';

const pageTitle = 'Patient health questionnaire form';

export default function MentalHealthScreener(props) {
  //UseProgress Context Hook
  const { updateProgress } = useProgress();

  // Breakpoints
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { patientIdentifier } = props;
  // Set loading state.
  const [loading, setLoading] = useState(false);
  // Set error alert state.
  const [errorAlert, setErrorAlert] = useState(false);
  // Set error alert message string.
  const [errorAlertMessage, setErrorAlertMessage] = useState('');
  // Form validation errors.
  const [formErrors, setFormErrors] = useState({
    phq2Q1: undefined,
    phq2Q2: undefined,
  });
  // PHQ2 questions data.
  const [phq2Q1, setPHQ2Q1] = useState('');
  const [phq2Q2, setPHQ2Q2] = useState('');
  // Set data for PHQ2
  const [token, setToken] = useState('');
  const [touchworksID, setTouchworksID] = useState('');
  const [providerID, setProviderID] = useState('');
  const [encounterID, setEncounterID] = useState('');
  const [informationPageShow, showInfoPage] = useState(false);
  const [resultsPageShow, showResultsPage] = useState(false);
  const [phqScore, setPhqScore] = useState(0);

  // Handle starting PHQ form.
  const startPHQ = () => {
    showInfoPage(false);
    showResultsPage(false);
  };

  useEffect(() => {
    const startPhqProcess = async () => {
      try {
        setLoading(true);
        // Set page titles
        document.querySelector('title').textContent = pageTitle;
        document.getElementById('pageTextHeader').innerHTML = pageTitle;
        // Set Value For Progress
        updateProgress(25);

        // Get Touchworks SSO Token
        const token = await touchworksService.getTouchworksToken();
        setToken(token);
        // Authenticate user and get provider id.
        const providerId = await touchworksService.getProviderID(token);
        setProviderID(providerId);
        // Get Internal Touchworks patient id for creating encounter.
        const internalTouchWorksPatientId =
          await touchworksService.getTouchworksPatientID(
            token,
            patientIdentifier,
          );
        setTouchworksID(internalTouchWorksPatientId);
        // Get encounter for patient.
        const encounterId = await touchworksService.createEncounter(
          token,
          internalTouchWorksPatientId,
        );
        setEncounterID(encounterId);
        setLoading(false);
        showInfoPage(true);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    startPhqProcess();
  }, []);

  // Validate the form fields.
  const validate = () => {
    const tempErrors = {};
    if (!phq2Q1) tempErrors.phq2Q1 = 'This field is required';
    if (!phq2Q2) tempErrors.phq2Q2 = 'This field is required';
    setFormErrors(tempErrors);
    return Object.keys(tempErrors).every((key) => tempErrors[key] === '');
  };

  // Handle submitting the form.
  //const onSubmit = async (data) => {
  const onSubmit = async () => {
    try {
      // Check form validation.
      if (validate()) {
        // Check for required params.
        if (!token || !providerID || !encounterID || !touchworksID) {
          throw 'Token, Provider Id, Encounter ID, or Internal Patient ID missing';
        }
        setLoading(true);
        // Calculate score.
        const score = parseInt(phq2Q1) + parseInt(phq2Q2);
        setPhqScore(score);
        // Format PHQ2 results
        const formattedResults = formatPHQ2Results(
          patientIdentifier,
          providerID,
          encounterID,
          phq2Q1,
          phq2Q2,
          score,
        );
        // Post results to Touchworks.
        const results = await touchworksService.saveMultipleResults(
          patientIdentifier,
          token,
          formattedResults,
        );
        // Check for failure.
        if (!results || results !== 'Success') {
          throw 'Something went wrong!';
        }
        // Retire the token.
        await touchworksService.retireTouchworksToken(token);
        // Show results page.
        showInfoPage(false);
        setLoading(false);
        showResultsPage(true);
      }
    } catch (e) {
      setLoading(false);
      // Set Error message
      setErrorAlertMessage('We ran into an issue. Please try again');
      //Keep error alert true for 5 seconds.
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
      }, 5000);
    }
  };

  // Reset the form seletions.
  const resetForm = () => {
    setPHQ2Q1('');
    setPHQ2Q2('');
  };

  // Show loading screen.
  if (loading) {
    return <Preloader fullScreen text="Loading ..." />;
  }

  // Show information page.
  if (informationPageShow) {
    return (
      <InformationPage
        onFormStateChange={startPHQ}
        patientIdentifier={patientIdentifier}
        token={token}
        providerID={providerID}
        encounterID={encounterID}
        touchworksID={touchworksID}
      />
    );
  }

  // Show results page.
  if (resultsPageShow) {
    return <ResultsPage score={phqScore} />;
  }

  return (
    <form name="PHQFORM">
      <Box className="pageContainer">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              type="h1"
              gutterBottom={true}
              style={{ marginTop: `${isMobile ? '40px' : '80px'}` }}
            >
              Over the last 2 weeks, how often have you been bothered by the
              following problems?
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'block',
            }}
          >
            <Typography
              type="body"
              size="small"
              weight="light"
              style={{ color: 'red' }}
            >
              {'* '}
            </Typography>
            <Typography type="body" size="small" weight="light">
              Required
            </Typography>
          </Grid>
          {/** In line error alert */}
          {(formErrors.phq2Q1 || formErrors.phq2Q2 || errorAlert) && (
            <Grid item xs={12} style={{ marginBottom: 24, width: 'inherit' }}>
              <InlineAlert
                severity="error"
                text={
                  errorAlert
                    ? errorAlertMessage
                    : `${
                        formErrors.phq2Q1 && formErrors.phq2Q2
                          ? 'There are 2 errors below'
                          : 'There is 1 error below'
                      }`
                }
              />
            </Grid>
          )}
          {PHQ2.questions.map((question) => {
            return (
              <Grid item xs={12} style={{ marginBottom: 18 }} key={question.id}>
                <RadioButtons
                  required
                  id={question.id}
                  formLabel={question.value}
                  defaultValue={
                    question.id === PHQ2.questions[0].id ? phq2Q1 : phq2Q2
                  }
                  options={PHQ2.answers.map((answer, i) => {
                    return {
                      value: answer.numValue,
                      label: answer.textValue,
                    };
                  })}
                  onChange={(event) => {
                    question.id === PHQ2.questions[0].id
                      ? setPHQ2Q1(event.target.value)
                      : setPHQ2Q2(event.target.value);
                  }}
                  row={isMobile ? false : true}
                  error={
                    question.id === PHQ2.questions[0].id
                      ? !!formErrors?.phq2Q1
                      : !!formErrors?.phq2Q2
                  }
                  helperText={
                    question.id === PHQ2.questions[0].id
                      ? formErrors?.phq2Q1
                      : formErrors?.phq2Q2
                  }
                />
              </Grid>
            );
          })}
          <Grid container spacing={'16px'} style={{ margin: 'initial' }}>
            <Grid item>
              <Button
                type="button"
                text="Submit"
                size="large"
                color="primary"
                onClick={() => onSubmit()}
              />
            </Grid>
            <Grid item>
              <Button
                type="button"
                text="Reset"
                size="large"
                color="secondary"
                onClick={() => resetForm()}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}
